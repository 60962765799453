var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "public-footer",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "content",
    class: _vm.$mq
  }, [_c('Logo'), _c('ul', {
    staticClass: "nav",
    class: _vm.$mq
  }, [_c('li', [_c('router-link', {
    attrs: {
      "to": "/features"
    }
  }, [_vm._v("Возможности сервиса")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/pricing"
    }
  }, [_vm._v("Тарифы")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/contact"
    }
  }, [_vm._v("Контакты")])], 1)]), _c('ul', {
    staticClass: "nav",
    class: _vm.$mq
  }, [_vm._m(0), _c('li', [_c('router-link', {
    attrs: {
      "to": "/partner"
    }
  }, [_vm._v("Партнерская программа")])], 1)]), _vm.user !== null ? _c('div', {
    staticClass: "footer-auth"
  }, [_c('Auth', {
    attrs: {
      "user": _vm.user
    }
  }), _c('Plugin-btn', {
    staticClass: "footer-auth__plugin-btn",
    attrs: {
      "w100": ""
    }
  }, [_vm._v(" Расширение для браузеров ")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "footer-bottom",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "copy",
    class: _vm.$mq
  }, [_c('span', [_vm._v(" SalesFinder является зарегистрированным товарным знаком, исключительные права принадлежат ООО \"С-Файндер\" ")]), _c('span', [_vm._v(" ООО \"С-Файндер\" © " + _vm._s(_vm.year) + " ")]), _c('span', [_c('router-link', {
    attrs: {
      "to": "/agreement",
      "active-class": ""
    }
  }, [_vm._v(" Договор-оферта ")])], 1), _c('span', [_c('router-link', {
    attrs: {
      "to": "/privacy",
      "active-class": ""
    }
  }, [_vm._v(" Политика обработки персональных данных ")])], 1)]), _c('div', {
    staticClass: "footer-bottom__payment",
    class: _vm.$mq
  }, [_c('PaymentMethods')], 1)])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('li', [_c('a', {
    attrs: {
      "href": "https://help.salesfinder.ru/"
    }
  }, [_vm._v(" Обучающие материалы ")])]);
}];
export { render, staticRenderFns };