import DrawerMenu from "./DrawerMenu";
import Logo from "./common/P/Logo";
import Auth from "./common/P/Auth";
import NewsBar from "@/components/Alert/NewsBar.vue";
import dayjs from "dayjs";
export default {
  data() {
    return {
      drawerMenuIsOpen: false,
      menuItems: [{
        link: "/features",
        text: "Возможности сервиса"
      }, {
        link: "/pricing",
        text: "Тарифы"
      }, {
        link: "/contact",
        text: "Контакты"
      }, {
        link: "https://help.salesfinder.ru/salesfinder-plugin",
        text: "Плагин для браузера"
      }],
      newsBar_Show: process.env.VUE_APP_NEWS_BAR_TEXT ? true : false,
      newsBar_Text: process.env.VUE_APP_NEWS_BAR_TEXT,
      newsBar_Btn: process.env.VUE_APP_NEWS_BAR_BTN,
      newsBar_Link: process.env.VUE_APP_NEWS_BAR_LINK,
      newsBar_Class: process.env.VUE_APP_NEWS_BAR_CLASS
    };
  },
  created() {
    this.$store.dispatch("user/getUser");
  },
  mounted() {
    this.newsBar_Show = process.env.VUE_APP_NEWS_BAR_TEXT ? true : false;
  },
  methods: {
    closeDrawerMenu() {
      this.drawerMenuIsOpen = false;
    },
    openDrawerMenu() {
      this.drawerMenuIsOpen = true;
    }
  },
  computed: {
    newsBar_EndTime() {
      if (process.env.VUE_APP_NEWS_BAR_ENDTIME) {
        let EndTime = process.env.VUE_APP_NEWS_BAR_ENDTIME;
        //console.log('89: EndTime=',EndTime)
        let Now = dayjs().format("YYYY-MM-DD HH:mm");
        //console.log('91: Now=',Now)
        if (EndTime < Now) {
          //console.log('94: 1')
          return 1;
        }
        //console.log('97: 0')
        return 0;
      } else {
        //console.log('100: 0')
        return 0;
      }
    },
    user() {
      return this.$store.state.user.user;
    }
  },
  components: {
    DrawerMenu,
    Logo,
    Auth,
    NewsBar
  }
};