var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "bank-card-icons",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "bank-card-icons__item",
    class: _vm.$mq
  }, [_c('Wechat')], 1), _c('div', {
    staticClass: "bank-card-icons__item",
    class: _vm.$mq
  }, [_c('Visa')], 1), _c('div', {
    staticClass: "bank-card-icons__item",
    class: _vm.$mq
  }, [_c('Mastercard')], 1), _c('div', {
    staticClass: "bank-card-icons__item",
    class: _vm.$mq
  }, [_c('Mir')], 1), _c('div', {
    staticClass: "bank-card-icons__item",
    class: _vm.$mq
  }, [_c('Unionpay')], 1), _c('div', {
    staticClass: "bank-card-icons__item",
    class: _vm.$mq
  }, [_c('Yoomoney')], 1), _c('div', {
    staticClass: "bank-card-icons__item with-text",
    class: _vm.$mq
  }, [_c('Invoice'), _vm._m(0)], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', {
    staticClass: "bank-card-icons__item__text"
  }, [_vm._v(" Оплата "), _c('br'), _vm._v(" по счету ")]);
}];
export { render, staticRenderFns };