var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('router-link', {
    staticClass: "logo-wrapper",
    attrs: {
      "to": "/",
      "active-class": ""
    }
  }, [_c('div', {
    staticClass: "logo-image"
  }, [_c('img', {
    staticClass: "logo",
    class: _vm.$mq,
    attrs: {
      "src": require(`@/assets/images/logo.svg`),
      "alt": "Логотип"
    }
  }), _c('span', {
    staticClass: "logo-sign"
  }, [_vm._v("®")])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };