import Logo from "./common/P/Logo";
import Auth from "./common/P/Auth";
import PluginBtn from "@/components/PluginBtn.vue";
import PaymentMethods from "@/components/Billing/PaymentMethods.vue";
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  },
  created() {
    this.$store.dispatch("user/getUser");
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    }
  },
  components: {
    Logo,
    Auth,
    PluginBtn,
    PaymentMethods
  }
};