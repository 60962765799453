import { SlideXRightTransition } from "vue2-transitions";
import Logo from "./common/P/Logo";
import Auth from "./common/P/Auth";
export default {
  data() {
    return {
      drag: false,
      scrollSettings: {
        suppressScrollX: true,
        suppressScrollY: false,
        wheelPropagation: false
      }
    };
  },
  props: {
    show: {
      required: true,
      type: Boolean
    },
    menuItems: {
      required: true
    },
    user: null
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
  },
  methods: {
    close() {
      this.$emit("close");
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  watch: {
    show(newVal) {
      if (newVal) document.body.style.overflow = "hidden";else document.body.style.overflow = "auto";
    }
  },
  components: {
    SlideXRightTransition,
    Logo,
    Auth
  }
};