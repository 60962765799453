var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('slide-x-right-transition', [_vm.show ? _c('div', {
    staticClass: "table-settings"
  }, [_c('div', {
    staticClass: "table-settings__bg",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.close.apply(null, arguments);
      }
    }
  }), _c('div', {
    staticClass: "table-settings__block",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "table-settings__header"
  }, [_c('Logo'), _c('svg', {
    staticClass: "table-settings__header-btn-close",
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_c('path', {
    attrs: {
      "d": "M17.5 0L10 7.5L2.5 0L0 2.5L7.5 10L0 17.5L2.5 20L10 12.5L17.5 20L20 17.5L12.5 10L20 2.5L17.5 0Z",
      "fill": "white"
    }
  })])], 1), _c('div', {
    staticClass: "table-settings__content"
  }, [_c('vue-custom-scrollbar', {
    staticClass: "table-settings__columns-drag-block",
    attrs: {
      "settings": _vm.scrollSettings
    }
  }, [_c('ul', {
    staticClass: "nav"
  }, _vm._l(_vm.menuItems, function (item, index) {
    return _c('li', {
      key: index
    }, [item.link.startsWith('/') ? _c('router-link', {
      attrs: {
        "to": item.link
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")]) : _c('a', {
      attrs: {
        "href": item.link,
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")])], 1);
  }), 0), _c('div', {
    staticStyle: {
      "padding": "20px"
    }
  }, [_vm.user !== null ? [_c('Auth', {
    attrs: {
      "user": _vm.user
    }
  })] : _vm._e()], 2)])], 1)])]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };