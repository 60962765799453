var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_vm.newsBar_Show && !_vm.newsBar_EndTime ? _c('news-bar', {
    attrs: {
      "text": _vm.newsBar_Text,
      "text-btn": _vm.newsBar_Btn,
      "link": _vm.newsBar_Link,
      "newsBarClass": _vm.newsBar_Class,
      "centered": ""
    },
    on: {
      "close": function ($event) {
        _vm.newsBar_Show = false;
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "public-header"
  }, [_vm.$mq === 'tablet' || _vm.$mq === 'mobile' ? _c('drawer-menu', {
    attrs: {
      "show": _vm.drawerMenuIsOpen,
      "menuItems": _vm.menuItems,
      "user": _vm.user
    },
    on: {
      "close": _vm.closeDrawerMenu
    }
  }) : _vm._e(), _c('Logo'), _c('ul', {
    staticClass: "nav",
    class: _vm.$mq
  }, _vm._l(_vm.menuItems, function (item, index) {
    return _c('li', {
      key: index
    }, [item.link.startsWith('/') ? _c('router-link', {
      attrs: {
        "to": item.link
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")]) : _c('a', {
      attrs: {
        "href": item.link,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(item.text))])], 1);
  }), 0), _vm.user !== null && _vm.$mq !== 'tablet' && _vm.$mq !== 'mobile' ? [_c('Auth', {
    attrs: {
      "user": _vm.user
    }
  })] : _vm._e(), _vm.$mq === 'tablet' || _vm.$mq === 'mobile' ? _c('button', {
    on: {
      "click": _vm.openDrawerMenu
    }
  }, [_c('img', {
    staticClass: "menu-icon",
    attrs: {
      "src": require('@/assets/images/icons/menu.svg'),
      "alt": "Menu Icon"
    }
  })]) : _vm._e()], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };