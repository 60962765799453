var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(_vm.tag, _vm._g(_vm._b({
    tag: "component",
    staticClass: "p-button",
    class: [this.variant, {
      block: _vm.block
    }]
  }, 'component', _vm.$attrs, false), _vm.$listeners), [_vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };