var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.user ? _c('div', {
    staticClass: "user-wrapper"
  }, [_c('user-dropdown', {
    attrs: {
      "white": ""
    }
  })], 1) : _c('div', {
    staticClass: "auth",
    class: _vm.$mq
  }, [_c('PButton', {
    staticClass: "btn",
    class: _vm.$mq,
    attrs: {
      "tag": "router-link",
      "variant": "secondary",
      "to": {
        name: 'Login'
      },
      "active-class": ""
    }
  }, [_vm._v(" Войти ")]), _c('PButton', {
    staticClass: "btn",
    class: _vm.$mq,
    attrs: {
      "tag": "router-link",
      "to": {
        name: 'Register'
      },
      "active-class": ""
    }
  }, [_vm._v(" Зарегистрироваться ")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };